import Input from "@/components/input";
import axios from "@/axios";
import loader from "@/components/loader";
import Button from "@/components/button";
import SubHeader from "@/components/SubHeader";
import Dropdown from "@shared/components/dropdown-base";


export default {
    name: "create-package",
    components: {
        "neo-input":Input,
        Button,
        axios,
        loader,
        SubHeader,
        Dropdown,
    },
    mounted(){
        
    }, 
    title: 'Create Package',

    data() {
        return {
            packageCreationInProgress:false,
            package:{
                package_name:'',
                package_name_internal:'',
                package_entity_type: '',
                package_description:'',
            },
            options: ['Company', 'Person'],
            placeholder: "Enter entity type",
            descriptionType: 'textarea'
        };
    },
    methods: {
        async createPackage(){
            this.package.package_name = this.package.package_name.trim();
            try{
                let url = `/screening-package`;
                this.packageCreationInProgress = true
                await axios.post(url, this.package);
                this.$toast.success("Package created");
                this.$router.push(
                    {
                        "name":"Screening Package"
                    }
                )
            }
            catch(error){
                this.$toast.warning(error.response.data.detail  ,"Could not create package!");
            }

            this.packageCreationInProgress = false
        },
    },
};


